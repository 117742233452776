<template>
  <div class="flex items-center pl-2  mb-0.5 rounded h-13 hover:bg-gray-50">
    <section class="w-full">
      <div class="text-14px">{{ title }}</div>

      <div class="bar" v-if="!getRealtimeStatus">
        <div class="bar-fillable" />
        <div
          class="bar-fill"
          :style="`max-width: ${percentage}%; background-color: ${bgColor}`"
        />
      </div>
    </section>

    <div class="pl-1 ml-1 w-24 text-px">
      <span class="font-bold">{{ count }}</span>
      <span class="ml-2 text-gray-500"> {{ percentage }}%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBarZ',
  props: {
    title: {
      type: [String, Number],
      required: false,
      default: '',
    },
    percentage: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    count: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    bgColor: {
      type: String,
      required: false,
      default: '#2ecc71',
    },
  },
  computed: {
    getRealtimeStatus() {
      return this.$store.getters['liveMap/getRealtimeFilterStatus']
    },
  },
}
</script>

<style lang="scss" scoped>
.bar {
  height: 5px;
  width: 100%;
  color: white;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.bar-fillable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: #ecf0f1;
}

.bar-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  transition: 0.7s;
  animation: bar-fill 1 1.8s;
  background: #2ecc71;
}

// .bar-fill {
//   max-width: 60%;
// }

@-webkit-keyframes bar-fill {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}
</style>
