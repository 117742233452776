<script>
import Fuse from 'fuse.js'
import { mapGetters, mapState } from 'vuex'

import ProgressBarZ from '@/components/analytics/charts/ProgressBarZ.vue'
import HeartbeatStatus from '@/components/badge/HeartbeatStatus.vue'

export default {
  name: 'LDrawerSummary',
  components: {
    ProgressBarZ,
    HeartbeatStatus,
  },
  props: {
    reqInit: {
      type: Boolean,
      default: true,
      required: false,
    },

    reqBusy: {
      type: Boolean,
      default: true,
      required: false,
    },
  },

  data() {
    return {
      globalSearch: '',
      globalSearchResults: [],
      onGlobalSearchFocus: false,
    }
  },

  computed: {
    // todo: req state -> loader
    ...mapState({
      data: (state) => state.liveMap.resData,
      filteredFleet: (state) => state.liveMap.filteredFleet,
      vehicles: (state) => Object.values(state.liveMap.resData),
    }),

    ...mapGetters({
      summary: 'liveMap/getFilteredFleetSummary',
    }),
  },

  mounted() {
    // const vehicles = Object.values(this.data)
    this.fuse = new Fuse(this.vehicles, {
      keys: ['name', 'qr_code'],
    })
  },

  watch: {
    // updated is string
    globalSearch(updated) {
      if (updated.length > 0) {
        this.globalSearchResults = this.fuse.search(updated, { limit: 5 })
      } else {
        this.globalSearchResults = []
      }
    },
  },

  methods: {
    // data is a vehicle object
    onNavigate(to, data) {
      // console.log('xx', { data })
      const from = this.$options.name
      this.$emit(`navigate`, { to, from, data })
    },
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <div
      v-if="reqBusy || reqInit"
      class="py-1 px-5 w-full sm:py-3"
      style="max-height: 80vh; overflow:hidden"
    >
      <TheSuspense rounded>
        <SuspenseHeading img />
        <SuspenseText :lines="5" />
      </TheSuspense>

      <TheSuspense rounded>
        <SuspenseHeading img />
        <SuspenseText :lines="5" />
      </TheSuspense>
    </div>

    <div class="py-1 px-5 sm:py-3" v-if="!reqBusy && !reqInit">
      <div
        class="font-bold text-gray-400 text-19px"
        v-html="summary.htmlSentence"
      />

      <!-- {{ summary }} -->
      <!-- search-area -->
      <div class="relative">
        <input
          v-model="globalSearch"
          type="search"
          class="px-2 mt-4 search-area"
          placeholder="Search vehicles by Name / QRCode"
        />

        <div
          v-show="globalSearch.length > 0"
          class="absolute right-0 left-0 top-auto z-10 py-1 bg-white rounded rounded-t-none border border-t-0"
        >
          <!-- keep-typing -->
          <!-- <template
            v-if="globalSearch.length <= 1 && globalSearchResults.length === 0"
          >
            <div
              class="flex justify-between items-center py-2 px-2 text-sm font-medium"
            >
              <div>Keep typing...</div>

              <i class="block text-green-500 fas fa-cog fa-spin" />
            </div>
          </template> -->
          <!-- /keep-typing -->

          <!-- not-found -->
          <template
            v-if="globalSearch.length > 0 && globalSearchResults.length === 0"
          >
            <div
              class="flex justify-between items-center py-2 px-2 text-sm font-medium"
            >
              <div>Not found!</div>

              <i class="block text-red-500 fas fa-exclamation-circle" />
            </div>
          </template>
          <!-- /not-found -->

          <!-- results -->
          <template v-else-if="globalSearchResults.length > 0">
            <div
              class="flex justify-between items-center py-2 px-2 text-sm cursor-pointer hover:bg-gray-100"
              v-for="result in globalSearchResults"
              :key="result.item.id"
              @click="onNavigate('LDrawerDetails', result.item)"
            >
              <div>
                <span class="text-gray-700">{{ result.item.name }}</span>
                <br />
                <span class="text-xs text-gray-500">{{
                  result.item.qr_code
                }}</span>
              </div>

              <div class="w-16">
                <HeartbeatStatus
                  :status="result.item.lock.is_operational"
                  :text-class="`ml-2 text-xs`"
                  :is-available="result.item.is_available"
                />
              </div>
            </div>
          </template>
          <!-- /results -->
        </div>
      </div>
      <!-- /search-area -->

      <div
        class="flex justify-end items-center pb-3 mt-4 text-blue-600 border-b"
      >
        <span
          class="cursor-pointer text-14px"
          @click="onNavigate('LDrawerList', filteredFleet)"
        >
          View all vehicles <i class="text-xs fa fa-chevron-right" />
        </span>
      </div>

      <!-- Summary -->
      <section class="pb-3 mt-4 border-b">
        <span class="text-lg font-bold"> Utilization</span>
        <div class="grid grid-cols-4 gap-1">
          <div
            class="flex flex-col justify-center pl-3 h-20 rounded hover:bg-gray-100"
          >
            <span class="text-xl font-bold">
              {{ summary.connectivity.operational }}
            </span>
            <span class="text-xs">Active</span>
          </div>
          <div
            class="flex flex-col justify-center pl-3 h-20 rounded hover:bg-gray-100"
          >
            <span class="text-xl font-bold">
              {{ summary.summary.onTrip.count }}
            </span>
            <span class="text-xs">On Trip</span>
          </div>
          <div
            class="flex flex-col justify-center pl-3 h-20 rounded hover:bg-gray-100"
          >
            <span class="text-xl font-bold">
              {{ summary.summary.onReservation.count }}
            </span>
            <span class="text-xs">Reserved</span>
          </div>
          <div
            class="flex flex-col justify-center pl-3 h-20 rounded hover:bg-gray-100"
          >
            <span class="text-xl font-bold">
              {{ summary.connectivity.nonOperational }}
            </span>
            <span class="text-xs">Inactive</span>
          </div>
        </div>
      </section>
      <!-- /summary -->

      <!-- charts -->
      <section class="mt-4">
        <div class="mb-3 text-lg font-bold">Vehicle Status</div>
        <!-- asdaf -->
        <ProgressBarZ
          v-for="item in summary.summary"
          :key="item.text"
          :title="item.text"
          :count="item.count"
          :percentage="item.percentage"
        />
      </section>
      <!-- /charts -->
    </div>
  </div>
</template>
