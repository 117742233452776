import { render, staticRenderFns } from "./LDrawerDetails.vue?vue&type=template&id=31fd6805&scoped=true&"
import script from "./LDrawerDetails.vue?vue&type=script&lang=js&"
export * from "./LDrawerDetails.vue?vue&type=script&lang=js&"
import style0 from "./LDrawerDetails.vue?vue&type=style&index=0&id=31fd6805&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31fd6805",
  null
  
)

export default component.exports