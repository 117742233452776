<script>
import reconnectingWebsocket from 'reconnectingwebsocket'
import { mapState } from 'vuex'
import { getAskDownOptions } from '@/store/modules/live-map/live-map.helpers'

// import AppButton from '@/components/form/AppButton.vue'
import RippleStatus from '@/components/badge/RippleStatus.vue'
// import ToggleBox from '@/components/form/ToggleBox.vue'
import SmartAlarmBox from '@/components/form/SmartAlarmBox.vue'
import AskDown from '@/components/dropdown/AskDown.vue'
import VehicleFlags from '@/components/badge/VehicleFlags'
import BatteryStatus from '@/components/badge/BatteryStatus.vue'
import SmartTag from '@/components/form/SmartTag.vue'
import SweetMark from '@/components/form/SweetMark.vue'
import CancelOrCompleteTrip from '@/composites/trip/shared/CancelOrCompleteTrip.vue'
import TaskItem from '@/views/LiveMap/TaskItem.vue'

import NoteItem from './NoteItem.vue'
import { VehicleConfig } from '@/config/VehicleConfig'
import SmartToggleBox from '@/components/form/SmartToggleBox.vue'

export default {
  name: 'LDrawerDetails',
  components: {
    // AppButton,
    RippleStatus,
    // ToggleBox,
    SmartAlarmBox,
    AskDown,
    VehicleFlags,
    BatteryStatus,
    SweetMark,
    SmartTag,
    NoteItem,
    CancelOrCompleteTrip,
    TaskItem,
    SmartToggleBox,
    OptionsTrackbar: () => import('@/components/form/OptionsTrackbar.vue'),
  },

  props: {
    reqInit: {
      type: Boolean,
      default: true,
      required: false,
    },

    reqBusy: {
      type: Boolean,
      default: true,
      required: false,
    },
  },

  data() {
    return {
      controls: {},

      // lock req statte
      lockReq: false,
      optionsTrackbarIsLoading: false,
      // notes
      notes: {
        add: false,
        model: '',
        saving: false,

        list: [],
      },
    }
  },

  computed: {
    ...mapState({
      req: (state) => state.liveMap.req,
      data: (state) => state.liveMap.drawer.details,
      geocoderData: (state) => state.liveMap.detailsGeocoderData,
      tagsList: (state) => state.liveMap.tagsList,
    }),

    getBatteryLevel() {
      return this?.data?.lock?.power_level || 0
    },

    throttleControl: {
      get() {
        return this.$store.state.liveMap.drawer.details.control_flags.throttle
      },
      set(v) {
        this.$store.state.liveMap.drawer.details.control_flags.throttle = v

        this.$store.commit('liveMap/setSubObjProp', {
          id: this.$store.state.liveMap.drawer.details.id,
          obj: 'control_flags',
          payload: {
            propKey: 'throttle',
            propValue: v,
          },
        })
      },
    },

    headlightControl: {
      get() {
        return this.$store.state.liveMap.drawer.details.control_flags.headlight
      },
      set(v) {
        // todo: use mutation? maybe not requored
        this.$store.state.liveMap.drawer.details.control_flags.headlight = v

        this.$store.commit('liveMap/setSubObjProp', {
          id: this.$store.state.liveMap.drawer.details.id,
          obj: 'control_flags',
          payload: {
            propKey: 'headlight',
            propValue: v,
          },
        })
      },
    },

    lockStatusControl: {
      get() {
        return this.$store.state.liveMap.drawer.details.lock.is_locked
      },
      set(v) {
        this.$store.state.liveMap.drawer.details.lock.is_locked = v
        this.$store.commit('liveMap/setSubObjProp', {
          id: this.$store.state.liveMap.drawer.details.id,
          obj: 'lock',
          payload: {
            propKey: 'is_locked',
            propValue: v,
          },
        })
      },
    },

    tagsControl: {
      get() {
        return this.$store.state.liveMap.drawer.details.tags
      },
    },

    getFlags() {
      return {
        ...this.data.general_flags,
        ...this.data.control_flags,
        ...this.data.operational_flags,
        ...this.data.status_flags,
      }
    },
  },

  beforeDestroy() {
    this.removeListener()
  },

  methods: {
    getAskDownOptions,
    async onClickOptionsTrackbar(e) {
      if (this.$acl.canUpdate('vehicles')) {
        this.$log.debug(e)
        const formData = new FormData()
        formData.append('mode', e.speed)

        this.optionsTrackbarIsLoading = true
        // this.startListener()
        await this.$http
          .post(`/dashboard/vehicles/${this.data.id}/speed-mode/`, formData)
          .then((res) => {
            this.$log.debug(res.data)

            this.$notify(
              {
                group: 'generic',
                type: 'default',
                title: 'Speed Mode',
                text:
                  res.data?.detail || 'Speed mode update request has been sent',
              },
              2000
            )
          })
          .catch((err) => {
            this.$log.error(err)
            this.$notify({
              group: 'generic',
              type: 'error',
              title: `Server Error [${err.response.status}]`,
              text: 'Unable to process the request!',
            })
          })
          .finally(() => {
            this.optionsTrackbarIsLoading = false
          })
      }
    },

    getTimeAgo(time) {
      //offset in hours

      // const tz = this.$dayjs.tz.guess()
      if (!this.$dayjs(time).isValid()) {
        return '--'
      }

      const clientTimezoneOffset = (new Date().getTimezoneOffset() / 60) * -1
      return this.$dayjs(time)
        .add(clientTimezoneOffset, 'hour')
        .fromNow()
    },

    onNavigate(to) {
      const from = this.$options.name
      this.$emit(`navigate`, { to, from })
    },

    async vehicleModeCallback(e) {
      const fd = new FormData()
      fd.append('bikes', this.data.id)
      fd.append('bike_mode', e.value)

      return await this.$http
        .post('/dashboard/vehicles/bulk-status-update/', fd)
        .then(() => {
          this.$store.commit('liveMap/setVehicleModeProp', {
            id: this.data.id,
            payload: {
              propKey: e.value,
              propValue: true,
            },
          })
          // alert('Okay: Vehicle mode updated!')
        })
        .catch(() => {
          alert('ERR: Vehicle mode could not be updated!')
        })
    },
    async singleVehicleModeCallback(e) {
      console.log('vehicleModeCallback', e)
      if (e.value === 'UV' || e.value === 'AV') {
        await this.vehicleModeCallback(e)
      } else {
        const url = VehicleConfig.api.updateOperationalFLags(this.data.id)

        let keyModel = (key) => {
          let aliases = {
            RB: 'rebalance',
            MT: 'maintenance',
            SB: 'swap_battery',
          }
          return aliases[key]
        }

        let formData = new FormData()
        formData.append(keyModel(e.value), true)
        await this.$http
          .patch(url, formData)
          .then(() => {
            this.$notify(
              {
                group: 'generic',
                type: 'success',
                title: 'Status updated',
                text: 'Status updated',
              },
              5000
            )
          })
          .catch((err) => {
            console.log('singleVehicleModeCallback-e', { err })

            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: 'Error',
                text: `${err.response.data.message}`,
              },
              5000
            )
          })
      }
    },

    onFlag(type) {
      console.log({ type })
    },

    viewOnGoogleMap(geo) {
      const url = `https://www.google.com/maps/search/?api=1&query=${geo.object.lat},${geo.object.lng}`
      window.open(url)
    },

    onRefetchVehicle(id) {
      // todo: api is incomplete
      this.$store.dispatch('liveMap/refetchVehicleData', { id })
      // console.log({ refetchData: id })
    },

    startListener() {
      let token = localStorage.getItem('token') || null
      // console.log(`Fetched Token : ${token}`)
      if (!token) return
      let sockUrl =
        this.$config.socket.baseUrl +
        this.$config.socket.channels.bikeNotifications +
        `?token=${token}`

      this.sockListener =
        this.sockListener ||
        new reconnectingWebsocket(sockUrl, null, this.$config.socket.configs())

      this.sockListener.onopen = function() {
        console.log('Opened Connection...')
      }
      this.sockListener.onclose = function() {
        console.log('Closed Connection...')
      }
      this.sockListener.onmessage = this.onMessageHandler
    },

    removeListener() {
      if (this.sockListener) {
        this.sockListener.close(4003, 'oto system closed the socket')
        this.sockListener = null
      }
    },

    onMessageHandler(message) {
      // validate payload
      console.log('Incoming --> ', message)

      let payload = JSON.parse(message.data)
      if (payload.n_type !== 'noti.bike_noti') return

      // handle payload consumption
      let data = payload.data
      console.log('Recieved Payload...', data)

      if ('headlight' in data) {
        this.headlightControl = data.headlight
        this.$notify({
          group: 'bottomRight',
          type: 'success',
          title: 'Headlight',
          text: `Headlight turned ${data.headlight ? 'on' : 'off'}`,
        })
      }

      if ('taillight' in data) {
        this.taillightControl = data.taillight
        this.$notify({
          group: 'bottomRight',
          type: 'success',
          title: 'Taillight',
          text: `Tail light turned ${data.taillight ? 'on' : 'off'}`,
        })
      }

      if ('throttle' in data) {
        this.throttleControl = data.throttle
        this.$notify({
          group: 'bottomRight',
          type: 'success',
          title: 'Throttling',
          text: `Throttling ${data.throttle ? 'enabled' : 'disabled'}`,
        })
      }
      //close socket
      // if (updatedData)
    },

    /**
     * Actions
     */

    async onChangeToggleBox(key, val, vehicleId) {
      console.log({ key, val, vehicleId })
      const url = this.$config.vehicle.api.updateControlFLags(vehicleId)
      const data = new FormData()
      data.append(key, val)

      // this.startListener()
      await this.$http
        .patch(url, data)
        .then(({ data }) => {
          console.log('Server Received...')
          console.log(data)
          this.$notify({
            group: 'bottomRight',
            type: 'success',
            title: 'Vehicle Flag',
            text: `Vehicle Flag Updated.`,
          })
        })
        .catch((err) => {
          // this.removeListener()
          this.$log.error(err)
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: `Server Error [${err.response.status}]`,
            text: 'Unable to process the request!',
          })
        })
    },

    async onRingAlarm(vehicleId) {
      console.log({ ts: this.$config.vehicle.api.ringAlarm(vehicleId) })
      const url = this.$config.vehicle.api.ringAlarm(vehicleId)

      await this.$http
        .post(url)
        .then(() => {
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: 'Alarm',
              text: 'Ringing alarm...',
            },
            10000
          )
        })
        .catch((err) => {
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: `Server Error [${err.response.status}]`,
            text: 'Unable to process the request!',
          })
        })
    },

    async handleBatteryUnlockClicked(vehicleId) {
      this.startListener()
      let url = VehicleConfig.api.unlockBattery(vehicleId)
      await this.$http
        .post(url)
        .then(() => {
          this.$notify({
            group: 'generic',
            type: 'success',
            title: 'Unlocked',
            text: 'Battery Unlock request sent...',
          })
        })
        .catch((err) => {
          this.removeListener()
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: `Server Error [${err.response.status}]`,
              text: 'Unable to process the request!',
            },
            5000
          )
        })
    },

    async onChangeLockStatus(vehicleId) {
      // if (!this.data.lock.isOperational) {
      //   alert('The vehicle is not operational!')
      //   return
      // }

      // prevent if already req is made
      if (this.lockReq === true) {
        return
      }

      this.lockReq = true

      // console.log({ lock: this.$config.vehicle.api.status.unlock(vehicleId) })

      const url =
        this.lockStatusControl === true
          ? this.$config.vehicle.api.status.lock(vehicleId)
          : this.$config.vehicle.api.status.unlock(vehicleId)

      let attempt
      try {
        this.$notify({
          group: 'bottomRight',
          type: 'danger',
          title: 'Processing...',
          text: `You'll be notified soon.`,
        })
        attempt = this.$http.post(url, new FormData())
      } catch (error) {
        console.log(error)
      }

      if (attempt) {
        console.log('wait...')
        await this.getVehicleLockStatus(vehicleId, !this.lockStatusControl)
      }
    },

    async getVehicleLockStatus(vehicleId, prevStatus, delay = 7000) {
      setTimeout(async () => {
        await this.$http
          .get(this.$config.vehicle.api.status.current(vehicleId))
          .then((res) => {
            console.log(res.data)
            // update only if the lock status has changed
            let mTitle = res.data.locked ? 'Locked' : 'Unlocked'

            if (res.data.locked !== prevStatus) {
              // this.lockStatusControl = res.data.locked // on reverse

              this.$notify({
                group: 'bottomRight',
                type: 'success',
                title: mTitle,
                text: `The vehicle is now ${mTitle.toLowerCase()}`,
              })
            } else {
              // action is the oposite of the current status
              const msg =
                this.lockStatusControl === true
                  ? 'Failed to unlock!'
                  : 'Failed to lock!'

              this.$notify({
                group: 'bottomRight',
                type: 'error',
                title: 'Lock Status',
                text: `${msg} Please try again`,
              })

              // this.lockStatusControl = false
              this.lockStatusControl = res.data.locked
            }
          })
          .catch((err) => {
            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Server Error [E:${err.response.status}]`,
              text: `Please try again later.`,
            })
          })
          .finally(() => {
            this.lockReq = false
          })
      }, delay)
    },

    /**
     * Notes
     */

    async onSaveNote(e, vehicleId) {
      console.log({ onSaveNote: e })

      const url = `/dashboard/entity-notes/bike/${vehicleId}/`
      const data = new FormData()
      data.append('details', this.notes.model)

      await this.$http.post(url, data).then(({ data }) => {
        console.log(data)
        // this.notes.list.push(r.data)
        this.$store.commit('liveMap/saveNote', {
          id: vehicleId,
          data,
        })
        this.notes.model = ''
      })
    },

    async onDeleteNote(note, parentId) {
      console.log({ onDeleteNote: note })

      this.$store.dispatch('liveMap/deleteNote', {
        parentId,
        entityId: note.id,
        entityType: 'bike',
      })
    },

    /**
     * Tags
     */

    async beforeCreatingTag({ entityId, tag }) {
      const url = `/dashboard/entity-tags/bike/${entityId}/`
      const data = new FormData()
      data.append('tag', tag)

      console.log('beforeCreatingTag', entityId, tag)
      return await this.$http.post(url, data)
    },

    async beforeAttachingTag({ entityId, tag }) {
      // probably, the backend uses findOrCreate()
      return await this.beforeCreatingTag({ entityId, tag })
    },

    async beforeDetachingTag({ tagId }) {
      const url = `/dashboard/entity-tags/${tagId}/`

      return await this.$http.delete(url)
    },

    onTagCreated(e) {
      console.log({ created: e })
      this.$store.commit('liveMap/onTagCreated', { data: e })
    },

    onTagAttached(e) {
      console.log({ attached: e })
      this.$store.commit('liveMap/onTagAttached', {
        entityId: this.data.id,
        data: e,
      })
    },

    onTagDetached(e) {
      console.log({ detached: e })
      this.$store.commit('liveMap/onTagDetached', {
        entityId: this.data.id,
        data: e,
      })
    },

    onTagListOpened(e) {
      console.log({ listOpened: e })
    },
    onTagListClosed(e) {
      console.log({ listClosed: e })
    },

    onCancelOrCompleteTrip({ action, tripId, vehicleId }) {
      const data = this.$store.state.liveMap.resData[vehicleId]
      data.status_flags.is_on_ride = false
      data.status_flags.is_reserved = false

      this.$store.commit('liveMap/syncVehicle', { id: vehicleId, data })
      console.log(`onCancelOrCompleteTrip ${action} ${tripId} ${vehicleId}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.vehicle-power {
  $width: 26px;
  $height: 50px;
  $widthCap: 8px;
  $heightCap: 5px;
  $bgFillable: #ededed;
  $rounded: 2px;

  position: relative;
  width: $width;
  height: $height + $heightCap;
  overflow: hidden;
  border-radius: $rounded;

  & .fillable {
    position: absolute;
    top: $heightCap;
    left: 0;
    width: $width;
    height: $height;
    background-color: $bgFillable;
    border-top-left-radius: $rounded;
    border-top-right-radius: $rounded;

    &::before {
      content: '';
      position: absolute;
      top: calc((#{$heightCap} - 0.2px) * -1);
      left: calc((#{$width} - #{$widthCap}) / 2);
      width: $widthCap;
      height: $heightCap;
      background-color: $bgFillable;
      display: block;
    }
  }

  & .fill {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(80% - #{$heightCap});
    background-color: #ff9b11;
    border-radius: $rounded;
  }
}
</style>

<template>
  <div>
    <div
      v-if="reqBusy || reqInit"
      class="py-1 px-5 w-full sm:py-3"
      style="max-height: 80vh; overflow:hidden"
    >
      <TheSuspense rounded>
        <SuspenseHeading img />
        <SuspenseText :lines="5" />
      </TheSuspense>

      <TheSuspense rounded>
        <SuspenseHeading img />
        <SuspenseText :lines="5" />
      </TheSuspense>
    </div>

    <div v-if="!reqBusy && !reqInit" class="pt-1 pb-3 sm:pt-5">
      <div class="flex items-center px-5">
        <div class="flex items-center w-2/3">
          <button
            class="py-1.5 px-3.5 app-form-reset hover:bg-gray-100 flex items-center app-form-reset"
            @click="onNavigate('LDrawerList')"
          >
            <i class="text-xs fa fa-arrow-left" />
          </button>
          <span class="ml-1 font-semibold text-19px">
            {{ data.name }}
          </span>
        </div>

        <div class="w-1/3 text-sm text-blue-600">
          <router-link
            :to="{
              name: 'ViewVehicleProfile',
              params: { id: data.id },
            }"
            class="text-sm text-blue-600 capitalize"
            target="_blank"
          >
            View Details
            <i class="text-xs fa fa-chevron-right" />
          </router-link>
        </div>
      </div>

      <!-- header -->
      <section class="flex px-5 mt-10">
        <div class="flex flex-col w-2/3">
          <div class="flex ml-6">
            <img
              src="@/assets/img/scooter.png"
              class="w-32 h-36"
              alt="scooter"
            />
          </div>

          <div class="flex justify-center -ml-12 text-sm">
            <CancelOrCompleteTrip
              :tripId="data.current_trip_id"
              :vehicleId="data.id"
              :isOnTrip="
                data.status_flags ? data.status_flags.is_on_ride : false
              "
              :isOnReservation="
                data.status_flags ? data.status_flags.is_reserved : false
              "
              @change="onCancelOrCompleteTrip"
            />
          </div>
        </div>

        <div class="flex flex-col w-1/3">
          <BatteryStatus
            :level="getBatteryLevel"
            :height="`50px`"
            :width="`26px`"
            :text-class="`pl-2 text-xl font-semibold`"
            vartical
          />

          <section class="flex flex-col gap-y-3 mt-4">
            <div class="text-lg font-bold tracking-tight">
              {{ data.qr_code }}
            </div>

            <div class="text-xs tracking-tight text-gray-500">
              {{ data.service_area ? data.service_area.name : '--' }}
            </div>
            <RippleStatus
              :status="data.lock ? data.lock.is_operational : false"
              :class="`-ml-2`"
              :text-class="
                `font-medium text-xs text-oDark
                  ${
                    (data.lock && data.lock.is_operational) || false
                      ? 'ml-3'
                      : 'ml-1'
                  }`
              "
            />

            <div class="text-xs tracking-tight text-blue-500">
              {{
                $truncate(geocoderData.display_name, 80, null, '...') || '--'
              }}
            </div>
          </section>
        </div>
      </section>
      <!-- /header -->

      <!-- status -->
      <div class="py-4 px-5 mt-5">
        <VehicleFlags
          :class="`grid grid-cols-3 gap-1`"
          :flags="getFlags"
          :event-enabled="true"
          :fallback="true"
          @parking="onFlag('parking')"
          @reservation="onFlag('reservation')"
          @on-ride="onFlag('on-ride')"
        />
      </div>
      <!-- /status -->

      <!-- location -->
      <section
        class="grid grid-cols-2 mt-5 border-t border-b border-gray-300 divide-x divide-gray-300"
      >
        <div class="flex flex-col justify-center py-4 px-4 h-20">
          <div class="flex items-center">
            <span class="font-bold text-14px">
              Last Updated
            </span>

            <button
              class="py-1 px-3 ml-1 rounded-full hover:bg-gray-100 app-form-reset"
              @click="onRefetchVehicle(data.id)"
            >
              <svg
                class="w-5 h-5 text-gray-500"
                :class="{ 'animate-spin': req.refetchVehicle }"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                ></path>
              </svg>
            </button>
          </div>

          <span class="mt-1 text-xs text-gray-500">
            {{ getTimeAgo(data.last_loc_updated_at) }}
          </span>
        </div>
        <div class="flex flex-col justify-center py-4 px-4 h-20">
          <div class="flex flex-col justify-center py-4 px-4 h-20">
            <div class="flex items-center">
              <span class="font-bold text-14px">
                Located At
              </span>

              <button
                class="py-1 px-3 ml-1 rounded-full hover:bg-gray-100 app-form-reset"
                @click="viewOnGoogleMap(data.geo)"
              >
                <svg
                  class="w-5 h-5 text-gray-500 transform scale-y-100 -scale-x-100"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
                  ></path>
                </svg>
              </button>
            </div>

            <span class="mt-1 text-xs text-gray-500">
              {{ Number(data.geo.object.lat).toFixed(5) }},
              {{ Number(data.geo.object.lng).toFixed(5) }}
            </span>
          </div>
        </div>
      </section>
      <!-- /location -->

      <!-- task item -->
      <TaskItem v-if="data.task" :data="data.task">
        <template #vehiclemode>
          <section class="flex justify-around items-center p-2">
            <span class="font-bold text-14px">Vehicle Mode</span>
            <AskDown
              :value="data.vehicleMode"
              :options="getAskDownOptions('vehicleModes')"
              :callback="singleVehicleModeCallback"
              shape-enabled
            />
          </section>
        </template>
      </TaskItem>

      <!-- /task item -->

      <!-- vehicle-mode -->
      <section
        v-if="data.task === null"
        class="flex justify-between items-center px-5 space-x-8 h-20 border-b"
      >
        <span class="font-bold text-14px">Vehicle Mode</span>
        <AskDown
          :value="data.vehicleMode"
          :options="getAskDownOptions('vehicleModes')"
          :callback="vehicleModeCallback"
          shape-enabled
        />
      </section>
      <!-- /vehicle-mode -->

      <!-- controls -->
      <section class="mt-2">
        <div class="flex items-center py-2">
          <span class="font-bold text-base mx-5">Hardware Actions</span>
        </div>
        <div class="flex items-center py-2">
          <div class="w-1/2 pl-5 pr-2 ">
            <SmartToggleBox
              v-model="lockStatusControl"
              :title="`Lock Status`"
              :onText="`Unlocked`"
              :offText="`Locked`"
              :onValue="false"
              :offValue="true"
              :size="`md`"
              @change="onChangeLockStatus(data.id, $event)"
            >
              <template #icon
                ><img
                  v-if="lockStatusControl"
                  src="@/assets/icon/lock-black.svg"
                />
                <img v-else src="@/assets/icon/unlock-white.svg" />
              </template>
            </SmartToggleBox>
          </div>
          <div class="w-1/2 pl-2 pr-5">
            <SmartToggleBox
              v-model="headlightControl"
              :title="`Headlight`"
              :onText="`On`"
              :offText="`Off`"
              :onValue="true"
              :offValue="false"
              :size="`md`"
              @change="onChangeToggleBox('headlight', $event, data.id)"
            >
              <template #icon>
                <img
                  v-if="headlightControl"
                  src="@/assets/icon/headlight-white.svg"
                />
                <img v-else src="@/assets/icon/headlight-black.svg" />
              </template>
            </SmartToggleBox>
          </div>
        </div>
        <div class="flex items-center py-2">
          <div class="w-1/2 pl-5 pr-2">
            <SmartToggleBox
              v-model="throttleControl"
              :title="`Throttle`"
              :onText="`On`"
              :offText="`Off`"
              :onValue="true"
              :offValue="false"
              :size="`md`"
              @change="onChangeToggleBox('throttle', $event, data.id)"
            >
              <template #icon>
                <img
                  v-if="throttleControl"
                  src="@/assets/icon/throttle-white.svg"
                />
                <img v-else src="@/assets/icon/throttle-black.svg" />
              </template>
            </SmartToggleBox>
          </div>
          <div class="w-1/2 pl-2 pr-5">
            <SmartAlarmBox
              v-model="controls.alarm"
              title="Ring"
              subtitle="Trigger ring"
              size="md"
              @click="onRingAlarm(data.id)"
            />
          </div>
        </div>
        <div class="flex items-center py-2">
          <div class="w-1/2 pl-5 pr-2">
            <SmartAlarmBox
              title="Unlock Battery"
              subtitle="Unlock battery compartment"
              size="md"
              @click="handleBatteryUnlockClicked(data.id)"
            />
          </div>
          <div class="w-1/2 pl-2 pr-5"></div>
        </div>
        <div class="px-5 py-2">
          <OptionsTrackbar
            v-model="data.scooter_extras.speed"
            :size="`lg`"
            :options="[
              {
                title: 'Low',
                subtitle: `${data.scooter_extras.low_speed_limit} km/hour`,
                speed: 'L',
              },
              {
                title: 'Medium',
                subtitle: `${data.scooter_extras.medium_speed_limit} km/hour`,
                speed: 'M',
              },
              {
                title: 'High',
                subtitle: `${data.scooter_extras.high_speed_limit} km/hour`,
                speed: 'H',
              },
            ]"
            :title-attribute="`title`"
            :subtitle-attribute="`subtitle`"
            :value-attribute="`speed`"
            :clicked-item-is-loading="optionsTrackbarIsLoading"
            @click="onClickOptionsTrackbar"
          />
        </div>
      </section>
      <!-- <section class="flex flex-col gap-y-3 py-5 px-5 w-full border-b">
        <ToggleBox
          v-model="lockStatusControl"
          :title="`Lock Status`"
          :subtitle="`IOT / Lock status`"
          :onText="`Unlocked`"
          :offText="`Locked`"
          :onValue="false"
          :offValue="true"
          :size="`md`"
          @change="onChangeLockStatus(data.id, $event)"
        />

        <ToggleBox
          v-model="headlightControl"
          :title="`Headlight`"
          :subtitle="`Always on headlight`"
          :onText="`On`"
          :offText="`Off`"
          :onValue="true"
          :offValue="false"
          :size="`md`"
          @change="onChangeToggleBox('headlight', $event, data.id)"
        />

        <ToggleBox
          v-model="throttleControl"
          :title="`Throttle`"
          :subtitle="`Allow throttling`"
          :onText="`On`"
          :offText="`Off`"
          :onValue="true"
          :offValue="false"
          :size="`md`"
          @change="onChangeToggleBox('throttle', $event, data.id)"
        />

        <AlarmBox
          v-model="controls.alarm"
          title="Ring"
          subtitle="Trigger ring"
          size="md"
          @click="onRingAlarm(data.id)"
        />
      </section> -->
      <!-- /controls -->

      <!-- tags -->
      <section class="py-5 px-5 border-b">
        <div class="flex gap-3 items-center text-blue-600">
          <i class="text-sm fas fa-tags"></i>
          <span class="font-bold text-14px">Tags</span>
        </div>

        <div class="mt-2 taggables">
          <SmartTag
            :id="data.id"
            :active="data.tags"
            :all="tagsList"
            :element-count-for-start-arrow-scrolling="3"
            :tab-index="1"
            :tag-creation-enabled="true"
            :colors-enabled="false"
            :tag-color-default="'#2563eb'"
            :tag-list-label="'Select a tag'"
            :placeholder="'+Add tags'"
            :before-creating="beforeCreatingTag"
            :before-attaching="beforeAttachingTag"
            :before-detaching="beforeDetachingTag"
            @created="onTagCreated"
            @attached="onTagAttached"
            @detached="onTagDetached"
            @on-tag-list-opened="onTagListOpened"
            @on-tag-list-closed="onTagListClosed"
          />
        </div>
      </section>
      <!-- /tags -->

      <!-- notes -->
      <div class="py-5 px-5">
        <SweetMark
          v-model="notes.model"
          :placeholder="`Add notes..`"
          @save="onSaveNote($event, data.id)"
        />

        <div class="flex justify-between items-center mt-8 mb-2">
          <div class="flex gap-3 items-center">
            <i class="text-sm fas fa-comment-alt"></i>
            <span class="font-bold text-14px">Latest Notes</span>
          </div>

          <router-link
            :to="{ name: 'VehicleNoteIndex', params: { id: data.id } }"
            :target="`_blank`"
          >
            <div class="text-sm text-blue-600">
              <span class="pr-2">View All</span>
              <i class="text-xs fa fa-chevron-right" />
            </div>
          </router-link>
        </div>

        <template v-if="data.notes.length > 0">
          <NoteItem
            v-for="note in data.notes"
            :key="note.id"
            :data="note"
            @delete="onDeleteNote($event, data.id)"
          />
        </template>

        <template v-else>
          <p class="mt-5 text-sm text-gray-500">
            No notes added yet!
          </p>
        </template>
      </div>
      <!-- /notes -->
    </div>
  </div>
</template>
