<template>
  <div>
    <section v-if="reqInit" class="py-4 px-4">
      <TheSuspense rounded>
        <SuspenseHeading img />
        <SuspenseText :lines="5" />
      </TheSuspense>

      <TheSuspense rounded>
        <SuspenseHeading img />
        <SuspenseText :lines="5" />
      </TheSuspense>
    </section>

    <section v-if="!reqInit" class="relative">
      <header
        class="flex absolute top-0 right-0 left-0 justify-between items-center py-4 px-4 h-12 border-b"
      >
        <div class="text-lg font-bold">Filters</div>

        <button
          class="py-1 px-3 ml-1 rounded-full hover:bg-gray-100 app-form-reset"
          @click="$store.commit('liveMap/drawerRightVisibilityToggle')"
        >
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            ></path>
          </svg>
        </button>
      </header>
      <div class="h-12" />

      <main
        class="overflow-y-auto overflow-x-hidden pb-4 sb-farhan container-height"
      >
        <!-- <template v-if="getFilteredData">
        {{ getFilteredData }}
        <div v-for="(vehicle, index) in getFilteredData" :key="index">
            AV: {{ vehicle.is_available ? '1' : '0' }} LK:
            {{ vehicle.lock.is_locked ? '1' : '0' }} MD:
            {{ vehicle.vehicleMode }} FL:
            {{ vehicle.fleet.name }}
            {{ vehicle.name }}
          </div>
         </template> -->

        <div class="py-4 px-6 border-b">
          <TCheckbox
            :model="filterFleet.all"
            :label="`Fleets`"
            :name="`Fleets`"
            :variant="`liveMapTitle`"
            wrapped
            disabled
            class="ml-1"
          />

          <div class="h-1" />

          <div v-for="fleet in fleetsData" :key="`fleet-filter-${fleet.id}`">
            <TCheckbox
              :model="filterFleet.models[fleet.id]"
              :label="`${fleet.name}`"
              :name="fleet.id"
              :variant="`liveMap`"
              wrapped
              @change="
                $store.commit('liveMap/onFilterChange', {
                  group: 'filterFleet',
                  key: fleet.id,
                  value: $event,
                })
              "
            />
          </div>
        </div>

        <div class="py-4 px-6 border-b">
          <TCheckbox
            :model="filterConnectivity.all"
            :label="`Connectivity`"
            :name="`Connectivity`"
            :variant="`liveMapTitle`"
            wrapped
            class="ml-1"
          />

          <div class="h-1" />

          <TCheckbox
            :model="filterConnectivity.models.operational"
            :label="`Operational`"
            :name="`Operational`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterConnectivity',
                key: 'operational',
                value: $event,
              })
            "
          />

          <TCheckbox
            :model="filterConnectivity.models.nonOperational"
            :label="`Non Operational`"
            :name="`Non Operational`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterConnectivity',
                key: 'nonOperational',
                value: $event,
              })
            "
          />
        </div>

        <div class="py-4 px-6 border-b">
          <TCheckbox
            :model="filterLockStatus.all"
            :label="`Lock Status`"
            :name="`Lock Status`"
            :variant="`liveMapTitle`"
            wrapped
            class="ml-1"
          />

          <div class="h-1" />

          <TCheckbox
            :model="filterLockStatus.models.locked"
            :label="`Locked`"
            :name="`Locked`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterLockStatus',
                key: 'locked',
                value: $event,
              })
            "
          />
          <TCheckbox
            :model="filterLockStatus.models.unlocked"
            :label="`Unlocked`"
            :name="`Unlocked`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterLockStatus',
                key: 'unlocked',
                value: $event,
              })
            "
          />
        </div>

        <div class="py-4 px-6 border-b">
          <TCheckbox
            :model="filterVehicleMode.all"
            :label="`Vehicle Status`"
            :name="`Vehicle Status`"
            :variant="`liveMapTitle`"
            wrapped
            class="ml-1"
          />

          <div class="h-1" />

          <TCheckbox
            :model="filterVehicleMode.models.available"
            :label="`Available`"
            :name="`Available`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterVehicleMode',
                key: 'available',
                value: $event,
              })
            "
          />

          <TCheckbox
            :model="filterVehicleMode.models.unavailable"
            :label="`Unavailable`"
            :name="`Unavailable`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterVehicleMode',
                key: 'unavailable',
                value: $event,
              })
            "
          />

          <TCheckbox
            :model="filterVehicleMode.models.rebalance"
            :label="`Rebalance`"
            :name="`Rebalance`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterVehicleMode',
                key: 'rebalance',
                value: $event,
              })
            "
          />

          <TCheckbox
            :model="filterVehicleMode.models.maintenance"
            :label="`Maintenance`"
            :name="`Maintenance`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterVehicleMode',
                key: 'maintenance',
                value: $event,
              })
            "
          />

          <TCheckbox
            :model="filterVehicleMode.models.swapBattery"
            :label="`Swap Battery`"
            :name="`Swap Battery`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterVehicleMode',
                key: 'swapBattery',
                value: $event,
              })
            "
          />
        </div>

        <!-- border-b -->
        <div class="py-4 px-6">
          <TCheckbox
            :model="filterBatteryStatus.all"
            :label="`Battery Status`"
            :name="`Battery Status`"
            :variant="`liveMapTitle`"
            wrapped
            class="ml-1"
          />

          <div class="h-1" />

          <TCheckbox
            :model="filterBatteryStatus.models.low"
            :label="`Low (0-29%)`"
            :name="`Low (0-29%)`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterBatteryStatus',
                key: 'low',
                value: $event,
              })
            "
          />

          <TCheckbox
            :model="filterBatteryStatus.models.medium"
            :label="`Medium (30-69%)`"
            :name="`Low (30-69%)`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterBatteryStatus',
                key: 'medium',
                value: $event,
              })
            "
          />

          <TCheckbox
            :model="filterBatteryStatus.models.high"
            :label="`High (70-100%)`"
            :name="`High (70-100%)`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterBatteryStatus',
                key: 'high',
                value: $event,
              })
            "
          />
        </div>

        <!-- <div class="py-4 px-6">
          <TCheckbox
            :model="filterBatteryStatus.all"
            :label="`Flags`"
            :name="`Flags`"
            :variant="`liveMapTitle`"
            wrapped
            class="ml-1"
          />

          <div class="h-1" />

          <TCheckbox
            :model="filterBatteryStatus.models.low"
            :label="`Low (0-29%)`"
            :name="`Low (0-29%)`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterBatteryStatus',
                key: 'low',
                value: $event,
              })
            "
          />

          <TCheckbox
            :model="filterBatteryStatus.models.medium"
            :label="`Medium (30-69%)`"
            :name="`Low (30-69%)`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterBatteryStatus',
                key: 'medium',
                value: $event,
              })
            "
          />

          <TCheckbox
            :model="filterBatteryStatus.models.high"
            :label="`High (70-100%)`"
            :name="`High (70-100%)`"
            :variant="`liveMap`"
            wrapped
            @change="
              $store.commit('liveMap/onFilterChange', {
                group: 'filterBatteryStatus',
                key: 'high',
                value: $event,
              })
            "
          />
        </div> -->
      </main>

      <div class="h-12" />
      <footer
        class="flex absolute right-0 bottom-0 left-0 gap-4 justify-end items-center py-4 px-4 h-12 border-t"
      >
        <AppButton
          :height="`31px`"
          :variant="`secondary`"
          :text="`Close`"
          @click="onFilterGroupClearAll"
        />

        <AppButton
          :height="`31px`"
          :variant="getFilteredDataCount > 0 ? `green` : `secondary`"
          :disabled="getFilteredDataCount <= 0"
          :text="`Show ${getFilteredDataCount} Vehicles`"
          @click="onFilterApplyMultiple"
        />
      </footer>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'LiveMapFilterAll',

  components: {
    AppButton: () => import('@/components/form/AppButton'),
  },

  computed: {
    ...mapState({
      reqBusy: (state) => state.liveMap.req.busy,
      reqInit: (state) => state.liveMap.req.init,

      // filterFleet: (state) => state.liveMap.filterFleet,
      // filterConnectivity: (state) => state.liveMap.filterConnectivity,
      // filterBatteryStatus: (state) => state.liveMap.filterBatteryStatus,
      // filterLockStatus: (state) => state.liveMap.filterLockStatus,
      // filterVehicleMode: (state) => state.liveMap.filterVehicleMode,
      // vehicles: (state) => state.liveMap.data,

      filters: (state) => state.liveMap.filters,

      filterFleet: (state) => state.liveMap.filters.filterFleet,
      filterVehicleType: (state) => state.liveMap.filters.filterVehicleType,
      filterConnectivity: (state) => state.liveMap.filters.filterConnectivity,
      filterBatteryStatus: (state) => state.liveMap.filters.filterBatteryStatus,
      filterLockStatus: (state) => state.liveMap.filters.filterLockStatus,
      filterVehicleMode: (state) => state.liveMap.filters.filterVehicleMode,
      filterVehicleStatus: (state) => state.liveMap.filters.filterVehicleStatus,
      // vehicles: (state) => state.liveMap.data,
    }),

    ...mapGetters({
      // resData: 'liveMap/getResData',
      // resMeta: 'liveMap/getResMeta',
      fleetsData: 'liveMap/getFleetsData',
      getFilteredData: 'liveMap/getFilteredData',
      getFilterFleetSelected: 'liveMap/getFilterFleetSelected',
      // getFilteredFleetVehicles: 'liveMap/getFilteredFleetVehicles',
    }),

    getFilteredDataCount() {
      return Object.keys(this.getFilteredData).length
    },
  },

  methods: {
    onFilterApplyMultiple() {
      const { operational, nonOperational } = this.filterConnectivity.models
      const { locked, unlocked } = this.filterLockStatus.models
      const { low, medium, high } = this.filterBatteryStatus.models
      const {
        available,
        unavailable,
        rebalance,
        maintenance,
        swapBattery,
      } = this.filterVehicleMode.models

      // fleet filter is the source of data, it should be excluded
      const itHasFleetFilter =
        Object.keys(this.getFilterFleetSelected).length > 0
      const itHasConnectivityFilter = operational || nonOperational
      const itHasLockStatusFilter = locked || unlocked
      const itHasVehicleModeFilter =
        available || unavailable || rebalance || maintenance || swapBattery
      const itHasBatteryStatusFilter = low || medium || high

      const groups = []
      if (itHasFleetFilter) groups.push({ name: 'filterFleet' })

      if (itHasConnectivityFilter) groups.push({ name: 'filterConnectivity' })

      if (itHasLockStatusFilter) groups.push({ name: 'filterLockStatus' })

      if (itHasVehicleModeFilter) groups.push({ name: 'filterVehicleMode' })

      if (itHasBatteryStatusFilter) groups.push({ name: 'filterBatteryStatus' })

      if (groups.length > 0) {
        this.$store.dispatch('liveMap/onFilterApplyMultiple', {
          groups,
          isApplied: true,
        })
      }
    },

    onFilterGroupClearAll() {
      // this.$store.dispatch('liveMap/onFilterClear', { all: true })

      this.$store.commit('liveMap/drawerRightVisibilityToggle')
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-all-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.container-height {
  // keep the bottom drawer
  height: calc(72.2vh - 3rem - 40px);
  // height: calc(80vh - 60px);

  // on bigger screen bottom panel is hidden
  @media screen and (min-width: 640px) {
    height: calc(72.2vh - 3rem);
  }
}
</style>
