<script>
// some leaflet plugins (e.g. pixi) requires to be imported first
// before importing leaflet itself

/* eslint-disable-next-line no-unused-vars */
import LDraw from 'leaflet-draw'
/* eslint-disable-next-line no-unused-vars */
import LPixi from 'leaflet-pixi-overlay'
import * as PIXI from 'pixi.js'

import * as Vue2Leaflet from 'vue2-leaflet'
import Vue2LeafletMarkercluster from './Vue2LeafletMarkercluster'

import { latLng, Icon, icon } from 'leaflet'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'

import { default as pointInPolygon } from 'point-in-polygon'

import { mapGetters, mapState } from 'vuex'

import BaseLayout from '@/views/shared/BaseLayout.vue'
import LDrawerSummary from './LDrawerSummary'
import LDrawerDetails from './LDrawerDetails'
import LDrawerList from './LDrawerList'
import VehicleMarker from './VehicleMarker'
import LiveMapFilterAll from './LiveMapFilterAll'
import LiveMapFilterPills from './LiveMapFilterPills'
import { useEndpoints } from '@/composables'

// function rand(n) {
//   let max = n + 0.1
//   let min = n - 0.1
//   return Math.random() * (max - min) + min
// }

export default {
  name: 'LiveMapNew',

  components: {
    BaseLayout,
    'v-map': Vue2Leaflet.LMap,
    'v-tilelayer': Vue2Leaflet.LTileLayer,
    'v-icondefault': Vue2Leaflet.LIconDefault,
    'v-icon': Vue2Leaflet.LIcon,
    'v-marker': Vue2Leaflet.LMarker,
    'v-popup': Vue2Leaflet.LPopup,
    'v-tooltip': Vue2Leaflet.LTooltip,
    'v-marker-cluster': Vue2LeafletMarkercluster,
    'v-control-zoom': Vue2Leaflet.LControlZoom,

    LDrawerSummary,
    LDrawerDetails,
    LDrawerList,
    VehicleMarker,
    LiveMapFilterAll,
    LiveMapFilterPills,
  },

  data() {
    // let locations = []
    // for (let i = 0; i < 200; i++) {
    //   locations.push({
    //     id: i,
    //     latlng: latLng(rand(23.9205), rand(90.953646)),
    //     text: 'Bike ' + i,
    //   })
    // }
    let customicon = icon(
      Object.assign({}, Icon.Default.prototype.options, { iconUrl, shadowUrl })
    )
    return {
      icon: customicon,

      clusterOptions: {
        spiderfyOnMaxZoom: false,
        disableClusteringAtZoom: 15,
      },

      // initialLocation: latLng(23.9205, 90.953646),

      // transition for list page @see -> onNavigate()
      transitionName: '',

      areas: {
        service: [],
        parking: [],
        slowZone: [],
        restricted: [],
      },
      areasLoded: false,

      dbExpanded: false,
    }
  },

  computed: {
    // initialLocation
    getDefaultFleetLatLng() {
      const lat = parseFloat(this.$org?.default_fleet?.warehouse_lat) || 23.8103
      const lng = parseFloat(this.$org?.default_fleet?.warehouse_lon) || 90.4125
      return latLng(lat, lng)
    },

    ...mapState({
      drawerView: (state) => state.liveMap.drawerView,
      drawerViews: (state) => state.liveMap.drawerViews,

      drawerVisibility: (state) => state.liveMap.drawerVisibility,
      drawerBottomVisibility: (state) => state.liveMap.drawerBottomVisibility,
      drawerRightVisibility: (state) => state.liveMap.drawerRightVisibility,

      reqInit: (state) => state.liveMap.req.init,
      reqBusy: (state) => state.liveMap.req.busy,

      filterFleet: (state) => state.liveMap.filterFleet,
      filterConnectivity: (state) => state.liveMap.filterConnectivity,
      filterBatteryStatus: (state) => state.liveMap.filterBatteryStatus,
      filterLockStatus: (state) => state.liveMap.filterLockStatus,
      filterVehicleStatus: (state) => state.liveMap.filterVehicleStatus,
      filterLastAppliedAt: (state) => state.liveMap.filterLastAppliedAt,

      //
      filteredFleet: (state) => state.liveMap.filteredFleet,
    }),

    ...mapGetters({
      resData: 'liveMap/getResData',
      fleetsData: 'liveMap/getFleetsData',
      getVehicleList: 'liveMap/getVehicleList',
      getFilteredData: 'liveMap/getFilteredData',
    }),
  },

  async created() {
    // todo: move service areas to store
    await this.fetchAreas()
    // reqBusy is handled by the action
    await this.$store.dispatch('liveMap/fetchData')

    // console.log({ ser: this.areas.service })
    // console.log({ LiveMap: this.$store.state.liveMap })
  },

  mounted() {
    // leaflet map instance
    this.$nextTick(() => {
      const self = this
      this.map = this.$refs.map.mapObject
      setTimeout(function() {
        self.map.invalidateSize()
        console.log('invalidating')
      }, 2500)
      // console.log({ thisResData: this.resData })
      // console.log('areas', this.areas)
    })

    // update cluster options
    // setTimeout(() => {
    //   console.log('done')
    //   this.$nextTick(() => {
    //     this.clusterOptions = { disableClusteringAtZoom: 15 }
    //   })
    // }, 5000)

    // select vehicles by drawing
    this.$nextTick(() => {
      // FeatureGroup is to store different layers
      // Add a new FeatureGroup from the leaflet scope for drawing objects on map
      // let drawables = new window.L.FeatureGroup().addTo(this.map)
      this.drawables = new window.L.FeatureGroup()
      // console.log({ drawables: this.drawables })

      // this.map -> the non-reactive leaflet instance
      this.map.addLayer(this.drawables)

      // Create leaflet draw control menu
      this.drawControl = new window.L.Control.Draw({
        position: 'topright',
        draw: {
          polyline: false,
          polygon: {
            title: 'Select vehicles with polygon',
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
              color: 'orange',
              timeout: 2000,
              message: '<strong>Intersection is not allowed.<strong>',
            },
            showArea: true,
            metric: true, //m2
            repeatMode: false,
            shapeOptions: {
              polylineID: false,
              color: '#F59E0B',
              weight: 2,
              opacity: 1,
              lineCap: 'round',
              lineJoin: 'bevel',
              dashArray: 1,
              metric: true,
              fillColor: '#FCD34D',
              fillOpacity: 0.5,
            },
          },
          rectangle: {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
              color: 'orange',
              timeout: 2000,
              message: '<strong>Nicht erlauben<strong>',
            },
            showArea: true,
            metric: true, //m2
            repeatMode: false,
            shapeOptions: {
              polylineID: false,
              color: '#F59E0B',
              weight: 2,
              opacity: 1,
              lineCap: 'round',
              lineJoin: 'bevel',
              dashArray: 1,
              metric: true,
              fillColor: '#FCD34D',
              fillOpacity: 0.5,
            },
          },
          circle: false,
          marker: false,
          circlemarker: false,
        },
        edit: {
          featureGroup: this.drawables,
          remove: true,
          edit: false,
        },
      })

      // add draw control to the leaflet map
      this.map.addControl(this.drawControl)

      this.map.on(window.L.Draw.Event.CREATED, (e) => {
        // remove all previous drawn layers
        this.drawables.clearLayers()

        // console.log(this.drawControl)
        // console.log(this.drawables)
        // console.log({ e })

        // this.drawControl.removeAllLayers()

        const type = e.layerType,
          layer = e.layer
        if (type === 'marker') {
          // Do marker specific actions
        }

        // add the drawn layer
        this.drawables.addLayer(layer)
        console.log({ drawEnd: e })

        // find markers
        let polygon = layer.getLatLngs()
        const area = polygon[0].map((x) => {
          return [x.lng, x.lat]
        })

        console.log(area)
        // console.log({ drawnLatLng: polygon })

        const markersInside = {}

        const vehicles = this.resData
        for (const key in vehicles) {
          const vehicle = vehicles[key]

          if (pointInPolygon(vehicle.geo.lngLat, area)) {
            vehicle.isSelected = true
            markersInside[key] = vehicle
          }
        }

        // this.locations.forEach((x) => {
        //   // needs to be [lng, lat]
        //   const curr = [x.latlng.lng, x.latlng.lat]
        //   pointInPolygon(curr, area) && markersInside.push(x)
        // })

        console.log({ markersInside })

        // this.drawerView = 'LDrawerList'
        // this.drawerViewData.list = {
        //   vehicles: markersInside,
        // }
        this.$store.commit('liveMap/onDrawerListView', markersInside)

        this.$store.commit('liveMap/drawerSync', {
          view: 'LDrawerList',
          visibility: true,
        })

        // this.drawerVisibility = true

        // alert(`total selected: ${markersInside.length || 0} vehicles`)
      })

      // on clear draw -> reset selcted vehicles & set listView with current filtered vehicles
      this.map.on(window.L.Draw.Event.DELETED, () => {
        console.log('draw deleted', this.getFilteredData)

        const vehicles = this.getFilteredData
        for (const key in vehicles) {
          vehicles[key].isSelected = false
        }

        this.$store.commit('liveMap/onDrawerListView', vehicles)

        this.$store.commit('liveMap/drawerSync', {
          view: 'LDrawerList',
          visibility: true,
        })
      })
    })
  },

  watch: {
    filterLastAppliedAt(updated) {
      // const toCompare = Date.now() - 1
      if (updated) {
        // console.log('filterAppliedWatcher')
        this.$nextTick(() => {
          const latLngBounds = []
          for (const k in this.getVehicleList) {
            latLngBounds.push(this.getVehicleList[k].geo.latLng)
          }

          if (latLngBounds.length > 0) {
            this.map.fitBounds(latLngBounds)
          }
        })
      }
    },

    areasLoded(u) {
      if (u) {
        // this.drawServiceAreaOverlays()
        this.drawOverlays()
      }
    },
  },

  methods: {
    async onNavigate({ from, to, data }) {
      // todo: set data
      // console.log({ onN: from })

      const liveMapStore = this.$store.state.liveMap
      const views = liveMapStore.drawerViews

      if (
        (from === views.summary || from === views.details) &&
        to === views.list
      ) {
        // use transion only for the first render to prevent in-component re-rendering transition
        this.transitionName = 'fade'
        const timeout = setTimeout(() => {
          this.transitionName = ''
          clearTimeout(timeout)
        }, 500)
      }

      if (to === views.details || to === views.summary) {
        // console.log('reset viewing index')
        this.$store.commit('liveMap/syncListViewingIndex', { index: 0 })
      }

      // clear filter
      if (from === views.list && to === views.summary) {
        this.$store.commit('liveMap/onFilterGroupClearSelection', {
          except: 'filterFleet',
        })
      }

      if (to === views.details) {
        await this.onMarkerClick(data)
      } else if (to === views.list) {
        // if (to === 'LDrawerList') {
        this.$store.commit('liveMap/onDrawerListView', this.filteredFleet)
        // }
        // this.$store.commit('liveMap/onDrawerListView', this.resData)
        // liveMapStore.drawer.list = liveMapStore.res.data
        this.$store.commit('liveMap/drawerSync', {
          view: 'LDrawerList',
          visibility: true,
        })
      } else {
        this.$store.commit('liveMap/drawerSync', {
          view: to,
          visibility: true,
        })
      }
    },

    onClusterReady: () => {
      /* console.log('onClusterReady', e) */
    },

    onClusterClick: () => {
      // console.log('onClusterClick')
    },

    // @param e is the vehicle from derived marker click event
    async onMarkerClick(e) {
      // console.log(this.map)
      // console.log('onMarkerClick', e)

      this.map.flyTo(e.geo.leaflet, 18, { duration: 0.85 })

      this.$store.commit('liveMap/drawerSync', {
        view: 'LDrawerDetails',
      })

      // this actions will fetch latest veihicle data, notes & geocoder data then merge it with the vehcle state
      await this.$store
        .dispatch('liveMap/fetchVehicleData', { id: e.id })
        .then(() => {
          // retrieve synced vehicle data from state
          const vehicle = this.resData[e.id]
          // console.log('onNavigate -> to details', vehicle?.current_trip_id)

          this.$store.commit('liveMap/onDrawerDetailsView', vehicle)
          this.$store.commit('liveMap/drawerSync', {
            visibility: true,
          })
        })
    },

    getMarkerVariant({ isSelected, isFocused, vehicleId }) {
      if (this.$store.state.liveMap.drawer.details.id === vehicleId)
        return 'dark'
      // console.log({ isSelected, isFocused })
      else if (isSelected || isFocused) return 'dark'
      else return 'light'
    },

    getMarkerZIndexOffset({ vehicleId }) {
      if (this.$store.state.liveMap.drawer.details.id === vehicleId) return 10

      return
    },

    drawServiceAreaOverlays() {
      // draw areas (service/parking/slow-zone/restricted)
      this.$nextTick(() => {
        // coords array
        // const rawCoords =
        //   '{28.473239102666973,46.29752804464591},{28.303223915726335,47.482001398691004},{27.8113465194194,48.54065793521154},{27.049622018649483,49.36438163663448},{26.097078360539474,49.873588361945586},{25.05015028615007,50.02509270130271},{24.01208881306279,49.81318551528245},{23.082786707591033,49.26652577701558},{22.34984749991095,48.44265078003647},{21.88130431044208,47.42135374937851},{21.720212988586848,46.29752804464591},{21.88130431044208,45.1737023399133},{22.34984749991095,44.15240530925532},{23.082786707591033,43.32853031227623},{24.01208881306279,42.78187057400936},{25.05015028615007,42.56996338798909},{26.097078360539467,42.72146772734622},{27.049622018649483,43.23067445265733},{27.8113465194194,44.05439815408026},{28.303223915726335,45.1130546906008},'
        // let convertedCoords2 = this.fromCoordsStrToLatLngArr(rawCoords)
        // const el =
        //   '{-23.348667401524708,-48.29357296867094},{-21.87307747820827,-47.96398312492094},{-21.924045558024275,-45.79967648429594},{-23.363796526780455,-44.86583859367094},{-24.63838580412329,-47.30480343742094},'
        // let convertedCoords = this.fromCoordsStrToLatLngArr(el)
        // console.log('converted', { convertedCoords, convertedCoords2 })
        // const coords = [convertedCoords, convertedCoords2]
        // add overlays
        // console.log({ xx: this.areas.service })
        this.areas.service.forEach((serviceArea) =>
          this.addOverlay(serviceArea)
        )
      })
    },

    drawOverlays() {
      this.$nextTick(() => {
        // blue
        this.areas.parking.forEach((parkingArea) =>
          this.addOverlay(parkingArea, {
            strokeColor: 0x08abfb,
            strokeOpacity: 0.8,
            fillColor: 0x08abfb,
            fillOpacity: 0.2,
          })
        )

        // orange
        // this.areas.slowZone.forEach((slowZoneArea) =>
        //   this.addOverlay(slowZoneArea, {
        //     strokeColor: 0xf67606,
        //     strokeOpacity: 0.8,
        //     fillColor: 0xf67606,
        //     fillOpacity: 0.2,
        //   })
        // )

        // red
        this.areas.restricted.forEach((restrictedArea) =>
          this.addOverlay(restrictedArea, {
            strokeColor: 0xff0000,
            strokeOpacity: 1,
            fillColor: 0xff0000,
            fillOpacity: 0.2,
          })
        )

        // blue
        this.areas.service.forEach((serviceArea) =>
          this.addOverlay(serviceArea, {
            strokeColor: 0x00b44d,
            strokeOpacity: 1,
            fillColor: 0x00b44d,
            fillOpacity: 0.1,
          })
        )
      })
    },

    // eslint-disable-next-line no-unused-vars
    addOverlay(
      area,
      {
        strokeColor = 0x00b44d,
        strokeOpacity = 1,
        fillColor = 0x00b44d,
        fillOpacity = 0.1,
      } = {}
    ) {
      let projectedPolygon
      const shape = new PIXI.Graphics()

      const pixiContainer = new PIXI.Container()
      pixiContainer.addChild(shape)

      let prevZoom
      let firstDraw = true

      const pixiOverlay = window.L.pixiOverlay(function(utils) {
        const zoom = utils.getMap().getZoom()
        const container = utils.getContainer()
        const renderer = utils.getRenderer()
        const project = utils.latLngToLayerPoint
        const scale = utils.getScale()

        if (firstDraw) {
          projectedPolygon = area.coords.map((coords) => project(coords))
        }

        if (firstDraw || prevZoom !== zoom) {
          shape.clear()
          shape.lineStyle(3 / scale, strokeColor, strokeOpacity)
          shape.beginFill(fillColor, fillOpacity)
          projectedPolygon.forEach(function(coords, index) {
            if (index == 0) shape.moveTo(coords.x, coords.y)
            else shape.lineTo(coords.x, coords.y)
          })
          shape.endFill()
        }
        firstDraw = false
        prevZoom = zoom
        renderer.render(container)
      }, pixiContainer)

      pixiOverlay.addTo(this.map)
    },

    // tools
    getRandNum() {
      return Date.now()
    },

    fromCoordsStrToLatLngArr(coords) {
      if (!coords) return []

      // capture between all curly braces excluding the braces
      // though the commented regex is more accurate but the browser support
      // for positive look behind is limited. the first regex is fine but also mathces
      // strings with a single curly brace (edning with '}').
      const list = coords.match(/[^{}]+(?=})/g)
      // const list = coords.match(/(?<=\{)(.*?)(?=\})/g)

      // return mapped array with proper data type
      const mapped = list.map((c) => {
        const [lat, lng] = c.split(',')
        return [Number(lat), Number(lng)]
      })

      // push the first element at the end to complete the shape (as of pixi needs it like so)
      mapped.push(mapped[0])

      return mapped
    },

    async fetchAreas() {
      const reqs = [
        this.$http.get(useEndpoints.dropdown.serviceAreas()),
        this.$http.get(useEndpoints.dropdown.parkingAreas()),
        this.$http.get(useEndpoints.dropdown.slowSpeedAreas()),
        this.$http.get(useEndpoints.dropdown.restrictedAreas()),
      ]

      await this.$http
        .all(reqs)
        .then((responses) => {
          const [service, parking, slowZone, restricted] = responses.map(
            (r) => {
              const data = r.data.data
              // console.log('mapping')
              if (Array.isArray(data)) {
                return data.map((datum) => ({
                  id: datum.id,
                  name: datum.name,
                  coords: this.fromCoordsStrToLatLngArr(datum.coords),
                }))
              }
              return []
            }
          )

          this.areas = {
            service,
            parking,
            slowZone,
            restricted,
          }
          console.log('fetchAreas', this.areas)

          this.areasLoded = true
          /*
          data.data.forEach((serviceArea) => {
            this.areas.service.push({
              id: serviceArea.id,
              name: serviceArea.name,
              coords: this.fromCoordsStrToLatLngArr(serviceArea.coords),
            })
          })
           */
        })
        .catch((err) => {
          console.log({ serviceAreaFetchErr: err })
        })
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<style lang="scss">
@import '~leaflet/dist/leaflet.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
@import '~leaflet-draw/dist/leaflet.draw.css';

.leaflet-map-pane canvas {
  z-index: 10;
}
.leaflet-map-pane svg {
  z-index: 20;
}

.leaflet-tile-pane {
  z-index: 20;
}

.leaflet-pane {
  z-index: 40;
}

.leaflet-overlay-pane {
  z-index: 40;
}
.leaflet-shadow-pane {
  z-index: 50;
}
.leaflet-marker-pane {
  z-index: 60;
}
.leaflet-tooltip-pane {
  z-index: 65;
}
.leaflet-popup-pane {
  z-index: 70;
}

.leaflet-control {
  position: relative;
  z-index: 80;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 80;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 100;
  pointer-events: none;
}

.map-view {
  height: calc(100vh - 10%);
}
.p-0-i {
  padding: 0;
}

/* draw-marker */
.leaflet-marker-icon.leaflet-interactive {
  border-radius: 10px;
}

.drawer-left {
  // @apply transition-all duration-300 ease-in-out origin-top-left transform;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 40;
  overflow: hidden;

  transition: all 0.3s ease-out;
  transform: transalate(0, 0);
  transform-origin: top left;

  &.is-open {
    width: 430px;
    hieght: 100%;
    left: 40px;
    transform: translateX(-40px);
  }

  // added on open
  &.is-close {
    width: 2.75rem;
    height: 2.5rem;

    transform: translateX(calc((40px - 100%)));
  }
}

.drawer-right {
  @apply absolute top-0 bottom-0 overflow-hidden transition-all duration-300 ease-in-out transform origin-top-right;

  right: 0;
  z-index: 110;

  &.is-open {
    width: 300px;
    @apply h-full;
  }

  &.is-close {
    @apply h-0 w-0;
  }
}

.search-area {
  width: 100%;
  height: 52px;
  border-radius: 7px;
  border: solid 1px #ffffff;
  background-color: #f2f2f2;

  @apply outline-none px-2 appearance-none ring-0 focus:ring-0 focus:outline-none;
}
</style>

<style lang="scss" scoped>
$dbCollapsedHeight: 60px;

.map-container {
  position: relative;
  overflow-y: hidden;
}

.container-height {
  // keep the bottom drawer
  height: calc(80vh - 40px);

  // on bigger screen bottom panel is hidden
  @media screen and (min-width: 640px) {
    height: 80vh;
  }
}

.dba {
  transition: all 450ms cubic-bezier(0.32, 1, 0.23, 1) 100ms;
}
.drawer-bottom {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  transition: all 450ms cubic-bezier(0.32, 1, 0.23, 1) 0ms;
  z-index: 200;

  position: absolute; // fixed
  bottom: 0;

  // height: 80vh;
  width: 93%;
  margin: 0 auto;
  left: 9px;
  right: 9px;

  background-color: #fff;
  padding: 8px 24px 16px;
  box-sizing: border-box;
  box-shadow: /* 0px 10px 20px rgba(0, 0, 0, 0.22) ,*/ 0px 14px 56px
    rgba(0, 0, 0, 0.25);
  transform: translate(0, 100%);
  transition: all 450ms cubic-bezier(0.32, 1, 0.23, 1) 100ms;

  & .is-collapsed {
    max-height: 60px;
  }

  & .is-expanded {
    max-height: 80vh;
    display: block;
    // position: fixed;
    // top: 0;
    transform: translate(0, 0);
  }
}
</style>

<template>
  <base-layout>
    <LiveMapFilterPills />

    <div
      class="relative mt-px overflow-hidden bg-white rounded-b"
      style="min-height: 80vh"
    >
      <div class="map-container container-height">
        <v-map
          ref="map"
          :zoom="10"
          :center="getDefaultFleetLatLng"
          :options="{ zoomControl: false }"
        >
          <v-control-zoom position="bottomright" />
          <v-icondefault />

          <v-tilelayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />

          <v-marker-cluster
            v-if="!reqInit"
            :options="clusterOptions"
            @clusterclick="onClusterClick()" 
            @ready="onClusterReady"
          >
            <v-marker
              v-for="vehicle in getVehicleList"
              :key="vehicle.id"
              :name="vehicle.name"
              :lat-lng="vehicle.geo.leaflet"
              :options="{ riseOnHover: true }"
              :zIndexOffset="getMarkerZIndexOffset({ vehicleId: vehicle.id })"
              @click="onMarkerClick(vehicle)"
            >
              <v-icon :icon-anchor="[16, 37]" class-name="">
                <VehicleMarker
                  :vehicle="vehicle"
                  :power-level="vehicle.lock.power_level"
                  :variant="
                    getMarkerVariant({
                      isSelected: vehicle.isSelected,
                      isFocused: vehicle.isFocused,
                      vehicleId: vehicle.id,
                    })
                  "
                />
              </v-icon>

              <v-tooltip>
                <div class="px-4">
                  <b class="font-bold">{{ vehicle.name }}</b>
                  <br />
                  Power: {{ parseFloat(vehicle.lock.power_level).toFixed() }}%
                </div>
              </v-tooltip>
            </v-marker>
          </v-marker-cluster>
        </v-map>
      </div>

      <aside
        class="drawer-right"
        :class="drawerRightVisibility ? 'is-open' : 'is-close'"
      >
        <div
          class="w-full h-full overflow-auto transition duration-200 sb-farhan container-height"
          :class="
            drawerRightVisibility ? ' bg-white' : 'bg-transparent invisible'
          "
        >
          <LiveMapFilterAll />
        </div>
      </aside>

      <aside
        class="drawer-left"
        :class="drawerVisibility ? 'is-open' : 'is-close'"
      >
        <div class="hidden overflow-hidden sm:justify-between sm:flex">
          <div
            class="overflow-auto transition duration-200 sb-farhan"
            :class="
              drawerVisibility
                ? 'bg-white visible w-full h-full'
                : 'bg-transparent invisible w-0 h-0'
            "
            style="height: 80vh"
          >
            <!-- <transition :name="transitionName"> -->
            <component
              :is="drawerView"
              :key="getRandNum()"
              :req-init="reqInit"
              :req-busy="reqBusy"
              @navigate="onNavigate($event)"
            />
            <!-- </transition> -->
          </div>

          <button
            class="flex items-center justify-center h-10 text-white transform w-11 app-form-reset bg-oDark"
            :class="drawerVisibility ? 'rotate-90' : 'rotate-270'"
            @click="$store.commit('liveMap/drawerVisibilityToggle')"
          >
            <svg
              class="w-5 h-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>
        </div>
      </aside>

      <!-- bottom-panel -->
      <aside
        class="absolute bottom-0 left-0 right-0 w-full h-10 py-2 bg-white z-200 dba sm:hidden"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modal-label"
        aria-hidden="true"
        style="height: 80vh;"
        :style="
          drawerBottomVisibility ? 'max-height: 80vh' : 'max-height: 40px'
        "
      >
        <div class="flex items-center justify-center" style="height: 30px">
          <button
            class="flex flex-col items-center justify-center px-5 text-sm font-bold text-center text-gray-700 transition duration-150 ease-in-out rounded-full select-none group hover:bg-gray-50 hover:text-oGreen app-form-reset"
            @click="$store.commit('liveMap/drawerVisibilityToggle')"
          >
            <div
              class="w-12 h-1 transition duration-300 ease-in-out bg-gray-200 rounded-t-md group-hover:bg-green-200"
            />
            <span>Live Map</span>
          </button>
        </div>

        <div
          class="overflow-auto transition duration-200 sb-farhan"
          style="height: calc(80vh - 40px)"
        >
          <component
            :is="drawerView"
            :key="getRandNum()"
            :req-init="reqInit"
            :req-busy="reqBusy"
            @navigate="onNavigate($event)"
          />
        </div>
      </aside>
      <!-- /bottom-panel -->
    </div>
  </base-layout>
</template>
