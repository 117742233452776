<template>
  <div class="flex gap-2 items-start mt-3">
    <div class="w-12 h-10 bg-gray-100 rounded-full"></div>
    <div class="w-full">
      <div>
        <span class="text-sm font-bold">
          {{ data.created_by.full_name || '--' }}
        </span>

        <span class="pl-2 text-xs text-gray-500">
          {{ data.created_by.role_name || '--' }}
        </span>
      </div>
      <p class="text-sm">
        {{ data.details }}
      </p>

      <small class="text-gray-500">
        {{ getTimeAgo(data.created_at) }}
      </small>
    </div>

    <div class="">
      <TDropdown variant="smActions">
        <template
          #trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
        >
          <button
            class="app-form-reset"
            aria-label="Report Status Menu"
            aria-haspopup="true"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
          >
            <i class="text-sm text-gray-400 fas fa-ellipsis-v"></i>
          </button>
        </template>

        <template #default>
          <div class="py-1 bg-white rounded shadow">
            <div
              class="py-2 px-4 text-sm text-red-500 cursor-pointer hover:bg-red-100"
              @click="onDelete(data)"
            >
              Delete
            </div>
          </div>
        </template>
      </TDropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoteItem',

  props: {
    data: {
      type: Object,
    },
  },

  methods: {
    getTimeAgo(time) {
      return this.$dayjs(time)
        .add(6, 'hour')
        .fromNow()
    },
    onDelete(note) {
      this.$emit('delete', note)
    },
  },
}
</script>

<style lang="scss" scoped></style>
